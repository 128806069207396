.carousel.carousel-slider div img {
  width: 35%;
}


.carousel.carouse-slider div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel.carouse-slider div img {
  justify-content: center;
  align-items: center;
}

#gallery {
  background-color: hsla(204, 86%, 53%, 0.2);
  padding-top: 50px;
}
