#about-us {
  background-color: hsla(204, 86%, 53%, 0.2);
}

$table-breakpoint: 1023px;

#about-container {
  display: inline-flex;

  @media screen and (min-width: $table-breakpoint){
    margin-left: 25%;
    margin-right: 25%;
  }
}

div.column.is-one-third {
  display: flex;
  justify-content: center;
}
div.box {
  width: 240px;
}
figure.image img {
  border: 2px solid black;
}
