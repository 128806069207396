#service-cards {
  margin-top: 50px !important;
}

#service-cards div.card {
  padding-top: 70px;
}

.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }
