@import '../../sass/colors.scss';

#topbar, #topbar div.level-left p.content {
  margin-bottom: 0px !important;
}

.level {
  padding: 10px;
  background-color: $cyan;
}

p.content {
  span {
    strong, a {
      color: black;
    }
  }
}
