hr {
	height: 1px !important;
	border: none !important;
	background-color: black;
}

// /* Background images */
.bg {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
//
.bg-image1 {
	background-color: rgba(255, 255, 255, 0.5);
	background-blend-mode: lighten;
}

section#home div.container h4.subtitle.is-6 {
	font-weight: bold;
}

section#home {
	padding-top: 10%;
}
