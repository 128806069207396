@import '../node_modules/bulma/bulma.sass';
@import './sass/colors.scss';

.App {
  text-align: center;
}

a:hover {
  background-color: $cyan;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button, a.button {
  transition: all .2s ease-in-out;
}

button:hover, a.button:hover {
  transform: scale(1.05);
}
